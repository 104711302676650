// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-analysis-js": () => import("./../../../src/pages/analysis.js" /* webpackChunkName: "component---src-pages-analysis-js" */),
  "component---src-pages-be-in-touch-js": () => import("./../../../src/pages/be-in-touch.js" /* webpackChunkName: "component---src-pages-be-in-touch-js" */),
  "component---src-pages-coming-soon-js": () => import("./../../../src/pages/coming-soon.js" /* webpackChunkName: "component---src-pages-coming-soon-js" */),
  "component---src-pages-confirm-payment-js": () => import("./../../../src/pages/confirm-payment.js" /* webpackChunkName: "component---src-pages-confirm-payment-js" */),
  "component---src-pages-credit-card-js": () => import("./../../../src/pages/credit-card.js" /* webpackChunkName: "component---src-pages-credit-card-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-next-steps-js": () => import("./../../../src/pages/next-steps.js" /* webpackChunkName: "component---src-pages-next-steps-js" */),
  "component---src-pages-package-info-js": () => import("./../../../src/pages/package-info.js" /* webpackChunkName: "component---src-pages-package-info-js" */),
  "component---src-pages-payment-method-js": () => import("./../../../src/pages/payment-method.js" /* webpackChunkName: "component---src-pages-payment-method-js" */),
  "component---src-pages-quiz-js": () => import("./../../../src/pages/quiz.js" /* webpackChunkName: "component---src-pages-quiz-js" */),
  "component---src-pages-report-js": () => import("./../../../src/pages/report.js" /* webpackChunkName: "component---src-pages-report-js" */)
}

